<template>
  <!--
    The view for the Customer Worklog Overview Report generation
  -->
  <CustomerWorklogOverview />
</template>

<script>
export default {
  name: "CustomerWorklogOverviewView",
  components: {
    CustomerWorklogOverview: () => import('@/components/CustomerWorklog/CustomerWorklogOverview.vue')
  },
  metaInfo () {
    return {
      title: 'Customer Worklog Overview'
    }
  }
}
</script>
